import React from 'react'

import Layout from '../components/Layout'
import IntroSection from '../components/IntroSection'
import LeadMagnetBar from '../components/LeadMagnetBar'
import FeaturedPosts from '../components/FeaturedPosts'
import Bio from '../components/Bio'
import RecentPosts from '../components/RecentPosts'
import SEO from '../components/Seo'

const BlogIndex = (props) => {
  console.log(props)
  return (
    <Layout location={props.location} title='We Learn Code'>
      <SEO title='Recent Posts' pathname='recent-posts' />
      <div className='container post'>
        <h1>Recent Posts</h1>
        <RecentPosts posts={props.data.allMdx} />
      </div>
    </Layout>
  )
}

export default BlogIndex

export const PageQuery = graphql`
  query RecentBlogPosts {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: {
      fields: [frontmatter___date]
      order: DESC
      }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            title
          }
        }
      }
    }
}
`
